const userApi = {
  LOGIN_USER: "/api/auth/login",

  FORGOT_PASSWORD: "/api/auth/forgotpassword",

  GET_USER_DETAILS: "/api/auth/details",
  GET_ALL_USERS: `/api/restaurant/users`,

  CREATE_USER: `/api/restaurant/users/create`,

  UPDATE_USER: `/api/restaurant/users/update`,

  DELETE_USER: `/api/restaurant/users/delete`,

  TOGGLE_PRINT_SETTING: `/api/restaurant/setting/updateprintsetting`,
};
export default userApi;
